/**
 * Load all plugins on load
 */
document.addEventListener('DOMContentLoaded', function () {
  initHamburgerMenu();
});


/**
 * Add support for opening and closing the hamburger menu
 */
function initHamburgerMenu() {
  var flyoutMenu = document.querySelector('.js-flyout-menu');
  var triggerOpenButton = document.querySelector('.js-site-menu-open-trigger');
  var triggerCloseButton = document.querySelector('.js-site-menu-close-trigger');

  triggerOpenButton.addEventListener('click', function (evt) {
    evt.preventDefault();
    flyoutMenu.classList.add('site-layout__sidebar--open');
  });

  triggerCloseButton.addEventListener('click', function (evt) {
    evt.preventDefault();
    flyoutMenu.classList.remove('site-layout__sidebar--open');
  });
}


/**
 * Toggle cards
 */
(function initCardToggles($) {
  if (!$) return console.warn('jQuery not loaded');

  // Hide all togglable
  $('[data-toggle-detail]').hide();

  // Attach listeners to all of the togglers
  $('[data-toggle]')
    .on('click', function () {
      var $this = $(this);
      var contentId = $this.attr('data-toggle');
      var $contentElement = $('[data-toggle-detail="' + contentId + '"]')
      $contentElement.toggle();
    });

})(window.jQuery);


(function initDatepicker($) {
  if (!$) return console.warn('jQuery not loaded');

  var $datepicker = $('.js-datepicker');
  if ($datepicker && $datepicker.datepicker) {
    $datepicker.datepicker({
      prevText: '←',
      nextText: '→',
    })
  }

})(window.jQuery);


/**
 * for development only
 */
(function initDynamicNav($) {
  if (!$) return console.warn('jQuery not loaded');

  var $menu = $('#mainmenu');

  var menuItems = [
    { name: 'Dashboard', url: 'dashboard.html' },
    { name: 'Card Layout', url: 'index.html' },
    { name: 'Single Row Layout', url: 'single-row-layout.html' },
    { name: 'Forms', url: 'forms.html' },
    { name: 'Forms in Cards', url: 'forms-in-cards.html' },
    { name: 'Survey', url: 'survey.html' },
    { name: 'Notes', url: 'notes.html' },
    { name: 'Self-assessment', url: 'self-assessment.html' },
    { name: 'Timepoints', url: 'timepoints.html' },
    { name: 'Create Timepoints', url: 'create-timepoints.html' },
    { name: 'Fake Graphs', url: 'fake-graphs.html' },
    { name: 'Blank Page', url: '_template-page.html' },
  ];

  menuItems.reverse().forEach(function (itemData) {
    var menuListItem = $('<li>')
    menuListItem.addClass('main-menu__item');

    var menuLink = $('<a>')
    menuLink.attr({ href: itemData.url, class: 'main-menu__item-link' });
    menuLink.text(itemData.name);
    if (window.location.href.endsWith(itemData.url)) {
      menuLink.addClass('main-menu__item-link--active');
    }

    menuLink.appendTo(menuListItem);
    menuListItem.prependTo($menu);
  });

})(window.jQuery);

/**
 * tabs (navigation)
 */
(function initTabs($) {
  var $tabs = $('.js-tabs');

  $tabs.on('click', '[data-tab-set]', function () {
    var $currentTab = $(this);
    var tabSet = $currentTab.data('tab-set');

    $tabs
      .find(`[data-tab-set="${tabSet}"]`)
      .each(function (index, tab) {
        $(tab).removeClass('pill-tabs__item--active')
      });

    // Active state
    $currentTab.addClass('pill-tabs__item--active')

    // Toggle content, if applicable
    var tabContentId = $currentTab.data('tab-content');
    $('.js-tab-content').hide();
    $(`#${tabContentId}`).show();
  })
})(window.jQuery);

